export function getToken() {
    return localStorage.getItem('auth')
}

//保存登陆信息
export function saveAuth(token) {
    localStorage.setItem('auth', token)
}

export function clearAuth() {
    localStorage.removeItem('auth')
}


