import {createRouter, createWebHashHistory} from 'vue-router';
import {getToken} from '@/services/auth';

const history = createWebHashHistory();

const router = createRouter({
    history,
    routes: [
        //单个路由均为对象类型，path代表的是路径，component代表组件
        {
            path: '/',
            name: 'index',
            component: () => import('@/views/Index')

        },
        {
            path: '/play/:mid/:id?',
            name: 'play',
            component: () => import('@/views/Play')

        },
        {
            path: '/login',
            name: 'login',
            component: () => import('@/views/Login')

        },
        {
            path: '/register',
            name: 'register',
            component: () => import('@/views/Register')

        },
        {
            path: '/history',
            name: 'history',
            component: () => import('@/views/History')

        },
        {
            path: '/setting',
            name: 'setting',
            component: () => import('@/views/Setting')

        }
    ]
});

// 全局路由守卫
router.beforeEach((to, from, next) => {
    // 逻辑操作
    if (getToken() || to.path === "/login" || to.path === "/register"){
        next()
    }else{
        next("/login")
    }
})

export default router


