import {createApp} from 'vue'
import App from './App.vue'
import Vant from 'vant';
import 'vant/lib/index.css';
import router from "@/routers";
// import plyr from 'vue-plyr'
// import 'vue-plyr/dist/vue-plyr.css'





const app = createApp(App)

app.use(Vant)
// app.use(plyr,{
//     settings:['captions', 'quality', 'loop'],
//     controls:['play-large', 'play', 'progress', 'current-time', 'mute', 'volume', 'captions', 'settings', 'pip', 'airplay', 'fullscreen']
// })
app.use(router)
app.mount('#app')




